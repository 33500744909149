import React from 'react';

import css from '../ListingPage.module.css';

const handleDragStart = (e) => e.preventDefault();

const SimpleTextCard = ({ text = "", title = "" }) => {
  return text ? (
    <div className={css.simpleTextCard} onDragStart={handleDragStart}>
      <div className={css.border} />
      <div className={css.cardInfo}>
        <span className={css.cardLabel}>{title}</span>
        <span className={css.cardValue}>{text}</span>
      </div>
    </div>
  ) : null;
}

export default SimpleTextCard;
